import React, { useEffect } from "react"

export default function ScriptComponent() {
  useEffect(() => {
    // This runs the script
    // const myScript =
    require("../myscript.js")

    //  <!-- Bootstrap core JS-->
    // require('../dist/js/jquery.min') ;
    //  <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>

    // require('../dist/js/bootstrap.bundle.min');
    //  <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/js/bootstrap.bundle.min.js"></script>

    // <!-- Third party plugin JS-->
    // require('../dist/js/jquery.easing.min');
    //  <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.4.1/jquery.easing.min.js"></script>

    //  <!-- Contact form JS-->
    //  <script src="assets/mail/jqBootstrapValidation.js"></script>
    //  <script src="assets/mail/contact_me.js"></script>

    //  <!-- Core theme JS-->
    // require('../dist/js/scripts');
    //  <script src="js/scripts.js"></script>
  }, [])

  return <div />
}
