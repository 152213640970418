import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import ScriptComponent from "./scriptComponent"
import CookieConsent from "react-cookie-consent"

import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"

export default function Footer() {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          copyright
        }
      }
    }
  `)
  const location = useLocation()

  return (
    <div>
      <footer className="footer py-4">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 text-lg-left">
              Copyright © {data.site.siteMetadata.copyright} 2021 .
            </div>
            <div className="col-lg-4 my-3 my-lg-0">
              <Link className="btn btn-dark btn-social mx-2" to="#!">
                <i className="fab fa-twitter"></i>
              </Link>
              <Link className="btn btn-dark btn-social mx-2" to="#!">
                <i className="fab fa-facebook-f"></i>
              </Link>
              <Link className="btn btn-dark btn-social mx-2" to="#!">
                <i className="fab fa-linkedin-in"></i>
              </Link>
            </div>
            <div className="col-lg-4 text-lg-right">
              <Link className="mr-3" to="#!">
                Privacy Policy
              </Link>
              <Link to="#!">Terms of Use</Link>
            </div>
          </div>
        </div>
        <CookieConsent
          enableDeclineButton
          onDecline={() => {
            console.log("Declined")
          }}
          onAccept={() => {
            console.log("Accepted")
            console.log("location", location)
            initializeAndTrack(location)
          }}
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          cookieName="cb-gatsby-gdpr-google-tagmanager"
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
      </footer>
      <ScriptComponent />
    </div>
  )
}
