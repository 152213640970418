/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import Helmet from "react-helmet"

import PropTypes from "prop-types"
import Footer from "./footer"

// import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <>
      <div>
        <Helmet>
          <script
            data-ad-client="ca-pub-2680803905342464"
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
          ></script>
        </Helmet>
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
